.nav-sublink {
    margin-left: 10px;
}

.nav {
    gap: 14px;
    .nav-item {
        display: flex;
    }
    .nav-link {
        cursor: pointer;
        &.active:hover {
            background-color: #65b4f6;
        }
    }
}


.directoryProduct {
    .search_block {
        width: 100%;
        position: relative;

        .search_d_input {
            width: 100%;
            border-radius: 5px;
            border: 1px solid black;
        }
    }

    .search_d_products {
        position: absolute;
        width: 100%;
        background: #BDC3C7;
        border-radius: 5px;
        overflow-y: scroll;
        left: 0;
        height: 100%;
        display: none;
    }

    .product {
        width: 116px;
        margin: 4px;
        padding: 4px;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        overflow: hidden;
        background: #dfe4e9;
        border: 1px #BDC3C7 solid;
        border-radius: 3px;

        .name {
            min-height: 27px;
            overflow: hidden;
            line-height: 13px;
            font-size: 12px;
        }

        .price {
            height: 16px;
            font-size: 14px;
            font-weight: bold;
        }

        .category {
            margin-top: 10px;
            font-weight: bold;
        }
    }
}

