.reportPrint {
    .form-group {
        margin-bottom: 0;
    }

    .report_print, .report_view {
        display: inline-table;
        margin-left: 15px;
        height: 100%;
    }
}

