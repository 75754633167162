// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'directoryStatus';
@import 'directoryProduct';
@import 'orderForm';
@import 'orderList';
@import 'reportPrint';
@import 'options';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~air-datepicker/dist/css/datepicker.css';

select, input, textarea {
    background: #ececec !important;
    background-color: #ececec !important;
}

.card {
    background: whitesmoke !important;
    background-color: whitesmoke !important;
}

.js-delete-table-item {
    font-weight: bold;
    font-size: 18px;
    color: red;
    cursor: pointer;
}

*:focus {
    outline: none !important;
}

* {
    font-family: Arial, Helvetica, sans-serif !important;
    line-height: 16px;
    font-size: 13px;
}

._block {
    background: whitesmoke;
    position: absolute;
    top: 55px;
    left: 0;
    height: calc(100vh - 55px);
    width: 100%;
    z-index: 5;
    display: none;
}

.button_plus_minus {
    font-size: 32px;
    border: none;
    color: #74b8e5;
}
