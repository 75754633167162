.optionsForm {
    display: flex;
    min-height: calc(100vh - 87px);
    .card-body {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
        margin: 1rem;
    }
    .card-detail {
        width: 50%;
        max-width: 460px;
    }
    .tab-content{
        font-family: Arial, Helvetica, sans-serif !important;
        line-height: 16px;
        font-size: 13px;
        padding: 8px;
    }
    .card-product {
        width: 50%;
        padding: 8px;
        display: flex;
        flex-direction: column;
        .tab-content {
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 0.25rem;
            flex: 1;
        }
    }

    .form-group {
        display: flex;
        margin-bottom: 0.5rem;
        gap: 8px;
        align-items: center;
        label {
            margin: 0;
        }
    }
}