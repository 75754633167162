.orderForm {
    min-height: calc(100vh - 87px);

    .delete_product {
        font-size: 18px;
        cursor: pointer;
    }

    .search_block {
        width: 100%;
        position: relative;

        .search_input {
            width: 100%;
            border-radius: 5px;
            border: 1px solid black;
        }

        .search_products {
            position: absolute;
            width: 100%;
            background: #BDC3C7;
            height: calc(100vh - 117px);
            overflow-y: scroll;
            display: none;

            .product {
                width: 100%;
                text-align: left;
                margin: 0px;

                .name {
                    min-height: 0;
                    line-height: unset;
                }
            }
        }
    }


    .card-detail {
        width: 450px;
        border-right: 1px solid rgba(0, 0, 0, 0.125);
        @media screen and (max-width: 1200px) {
            width: 400px;
        }

        ._product_quantity {
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            -moz-appearance: textfield;
            width: 40px;
            text-align: center;
        }

        ._product_price {
            width: 70px;
        }

        #detail, #marks {
            .form-group {
                display: flex;
                margin-bottom: 0.5rem;

                label {
                    width: 100px;
                    margin: auto 0;
                    flex-shrink: 0;
                }
            }
        }

        .table_product {
            height: calc(100vh - 225px);
            overflow-y: scroll;
        }

        .order_total {
            padding: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.125);
            height: 95px;

            .top {
                display: flex;
                justify-content: space-between;

                .discount {
                    padding: 1px 3px;
                    color: whitesmoke;
                    background: #f35050;
                    border-radius: 5px;
                    cursor: pointer;
                    opacity: 0;
                }
            }

            .body {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;

                .left {
                    .percent {
                        background: #d5d5d5;
                        border: 1px solid #d7d7d7;
                        padding: 0 10px;
                        font-size: 20px;
                        color: #505050;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    .percent_menu {
                        position: absolute;
                        bottom: 50px;
                        background: whitesmoke;
                        width: 110px;
                        border-radius: 5px;
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
                        text-align: center;
                        padding: 7px;
                        display: none;

                        .add_discount, .discount_remove {
                            cursor: pointer;

                            &:hover {
                                background: #d7d7d7;
                                border-radius: 5px;
                                font-weight: bold;
                            }
                        }

                        .add_discount_money {
                            display: flex;
                            justify-content: space-between;
                            border-top: 1px solid rgba(0, 0, 0, 0.125);
                            margin-top: 3px;
                            flex-wrap: wrap;

                            div {
                                margin: auto 0;
                                justify-content: space-between;
                                width: 100%;
                                font-weight: bold;
                            }

                            input {
                                width: 58px;
                                border-radius: 5px;
                                border: 1px solid #d7d7d7;
                            }

                            button {
                                border: none;
                                border-radius: 5px;
                                background: #27AE60;
                                height: 100%;
                                color: whitesmoke;
                                font-weight: bold;
                                font-size: 17px;
                            }
                        }
                    }
                }

                .right {
                    display: flex;

                    .trash {
                        background: #d5d5d5;
                        border: 1px solid #d7d7d7;
                        padding: 0px 10px;
                        color: #505050;
                        border-radius: 5px;
                        cursor: pointer;

                        svg {
                            fill: #505050;
                        }
                    }

                    .save {
                        button {
                            border: none;
                            border-radius: 5px;
                            background: #27AE60;
                            height: 100%;
                            margin-left: 10px;
                            color: whitesmoke;
                            font-weight: bold;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }

    .card-product {
        width: calc(100% - 450px);
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1200px) {
            width: calc(100% - 400px);
        }

        .category_block {
            border-right: 1px solid rgba(0, 0, 0, 0.125);
            width: 200px;
            @media screen and (max-width: 1200px) {
                width: 170px;
            }
        }

        .tab-content {
            width: calc(100% - 200px);
            @media screen and (max-width: 1200px) {
                width: calc(100% - 170px);
            }
        }

        .nav-link {
            padding: 0.2rem;
        }
    }

    .product {
        width: 135px;
        margin: 1px;
        padding: 1px;
        text-align: center;
        cursor: pointer;
        font-size: 11px;
        overflow: hidden;
        background: #dfe4e9;
        border: 1px #BDC3C7 solid;
        border-radius: 2px;
        @media screen and (max-width: 1200px) {
            width: 115px;
        }

        .name {
            min-height: 27px;
            overflow: hidden;
            line-height: 13px;
            font-size: 12px;
        }

        .price {
            height: 16px;
            font-size: 14px;
            font-weight: bold;
        }
    }


}

.category_link {
    text-align: center;
    padding: 10px !important;
    background: rgb(223, 228, 233);;
    border: 1px solid #BDC3C7;
    margin: 2px 3px;
    color: black;

    &.active {
        background: #BDC3C7 !important;;
        background-color: #BDC3C7 !important;;
        color: black !important;
    }
}

#order_product_list {
    input {
        border: 1px solid #BDC3C7;
    }
}

