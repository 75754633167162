.orderList {
    .search_orders {
        width: 300px;
        border-radius: 5px;
        border: 1px solid black;
        float: right;
        margin-bottom: 10px;
    }

    .button_action {
        // color: #444;
        // background: #e2e2e2;
        // border: 1px solid #d7d7d7;
        // border-radius: 3px;
        // padding: 1px 6px;
        // display: inline-block;

        // &:hover {
        //     background: #d5d5d5;
        //     color: #555;
        // }
    }

    .order_api {
        margin-right: 5px;
        padding: 1px 4px 2px 4px;
        color: #03f;
        border: 1px solid #03f;
        display: inline;
        border-radius: 5px;
    }

    td {
        .form-group {
            margin: 0;
        }
    }

    .order_product_list {
        display: flex;
        justify-content: space-between;
    }

    .table th,
    .table td {
        vertical-align: inherit;
    }

    .delete_order {
        color: red;
        font-size: 20px;
        border: 1px solid red;
        padding: 0 5px;
        border-radius: 5px;

        &:hover {
            font-weight: bold;
            text-decoration: none;
            opacity: 0.7;
        }
    }

    .print_button {
        position: relative;

        .print_menu {
            display: none;
            position: absolute;
            z-index: 1;
            top: 30px;
            left: -20px;
            width: 100px;
            border-radius: 5px;
            background: #e2e2e2;
            border: 1px solid #d7d7d7;
        }
    }

    .period_filter {
        margin-left: 10px !important;
        width: 200px;
        display: flex;
    }
}

.orderListNew {
    .header_order {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .column1 {
            width: 15%;
        }

        .column2 {
            width: 20%;
        }

        .column3 {
            width: 15%;
        }

        .column4 {
            width: 20%;
        }

        .column5 {
            width: 30%;

            .search_orders {
                // border-radius: 5px;
                // margin-bottom: 10px;
                // border: 1px solid black;
                // width: 100%;
            }
        }
    }

    .filters {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 15px;

        //@media screen and (max-width: 1024px) {
        //
        //}

        .column1 {
            width: 15%;
            padding-right: 10px;
            @media screen and (max-width: 1550px) {
                width: 20%;
            }
        }

        .column2 {
            width: 15%;
            padding-right: 10px;
            @media screen and (max-width: 1550px) {
                width: 20%;
            }
        }

        .column3 {
            width: 40%;
            padding-right: 10px;
            @media screen and (max-width: 1550px) {
                width: 30%;
            }
        }

        .column4 {
            width: 10%;
            padding-right: 10px;
        }

        .column5 {
            width: 10%;
            padding-right: 10px;
        }

        .column6 {
            width: 10%;
        }
    }

    .list {
        .item {
            &_header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                background: #e7707d;

                .column1 {
                    width: 30%;
                    margin: auto 0;
                    @media screen and (max-width: 1550px) {
                        width: 40%;
                    }
                    display: flex;
                    align-items: center;
                }

                .column2 {
                    width: 40%;
                    padding-right: 10px;
                    @media screen and (max-width: 1550px) {
                        width: 30%;
                    }
                }

                .column3 {
                    width: 10%;
                    padding-right: 10px;
                }

                .column4 {
                    width: 10%;
                    padding-right: 10px;
                }

                .column5 {
                    width: 10%;
                    padding-right: 10px;
                }

                .button_action {
                    // color: #444;
                    // background: #e2e2e2;
                    // border: 1px solid #d7d7d7;
                    // border-radius: 3px;
                    // padding: 1px 6px;
                    // display: inline-block;

                    // &:hover {
                    //     background: #d5d5d5;
                    //     color: #555;
                    // }
                }

                .print_button {
                    position: relative;

                    .print_menu {
                        display: none;
                        position: absolute;
                        z-index: 1;
                        top: 30px;
                        left: -20px;
                        width: 100px;
                        border-radius: 5px;
                        background: #e2e2e2;
                        border: 1px solid #d7d7d7;
                    }
                }
            }
            &_export {
                width: 100%;
                display: flex;
                justify-content: space-between;
                background: #e7707d;
                align-items: center;

                .column1 {
                    width: 30%;
                    margin: auto 0;
                    @media screen and (max-width: 1550px) {
                        width: 40%;
                    }
                }

                .column2 {
                    width: 70%;
                    padding-right: 10px;
                    @media screen and (max-width: 1550px) {
                        width: 60%;
                    }
                }
            }

            &_body {
                background: #f6993f;
                width: 100%;
                display: flex;

                .column1 {
                    display: flex;
                    width: 30%;
                    padding-right: 10px;
                    @media screen and (max-width: 1550px) {
                        width: 40%;
                    }

                    .order_product_list {
                        width: 100%;

                        div:first-child {
                            width: 100%;
                        }

                        div:nth-child(2) {
                            width: 35px;
                        }

                        div:last-child {
                            width: 65px;
                            text-align: center;
                        }
                    }
                }

                .order_product_list {
                    display: flex;
                    justify-content: space-between;
                }

                .column3 {
                    width: 40%;
                    padding-right: 10px;
                    @media screen and (max-width: 1550px) {
                        width: 30%;
                    }
                }

                .column4 {
                    width: 10%;
                    padding-right: 10px;
                }

                .column5 {
                    width: 10%;
                    padding-right: 10px;
                }

                .column6 {
                    width: 10%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

    .order_api {
        margin-right: 5px;
        padding: 1px 4px 2px 4px;
        color: #03f;
        border: 1px solid #03f;
        display: inline;
        border-radius: 5px;
    }
}

.export-result {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999999;
    &__modal {
        display: flex;
        flex-direction: column;
        gap: 12px;
        background-color: #fff;
        border-radius: 13px;
        padding: 20px;
        width: 100%;
        max-width: 400px;
        min-height: 140px;
        align-items: center;
        justify-content: center;
    }
    &__button {
        color: #444;
        background: #e2e2e2;
        border: 1px solid #d7d7d7;
        border-radius: 3px;
        padding: 1px 6px;
        display: inline-block;
        &:hover {
            background: #d5d5d5;
            color: #555;
        }
    }
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}
.lds-spinner div {
    transform-origin: 20px 20px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 1.5px;
    left: 18.5px;
    width: 3px;
    height: 9px;
    border-radius: 20%;
    background: #8c8c8c;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
